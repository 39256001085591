(() => {
  const fesdDB = {
    library: {},
    // 紀錄使用的瀏覽器及裝置資訊
    is: {},
    // 各套件設定 (Beta.)
    video4: {
      SETTINGS: {
        videoId: null,
        videoType: null,
        videoAutoplay: 'off',
        videoMode: 'onBox',
        videoButton: '.playButton',
        videoCover: 'on',
        videoLayoutNo: 0,
        videoTarget: 'video-template',
        videoTargetRoute: './video4_lightbox.html',
        videoVimeoHash: null,
      },
      ATTRS: {
        id: 'video-id',
        type: 'video-type',
        autoplay: 'video-autoplay',
        hash: 'vimeo-hash',
      },
      EVENTS: {
        init: null,
        afterInit: null,
        beforeDestroy: null,
        afterUpdate: null,
      },
      // video4 target 內放置結構 index 對應 videoLayoutNo
      LAYOUT: ['<div class="playButton"></div><div class="overlay"></div>'],
      // videoPlayer customElements 內結構
      TEMPLATE() {
        return `<div class="player-container"><div class="player-wrapper"></div></div>`;
      },
    },
    share4: {
      SETTINGS: {
        success: null,
        text: null,
        className: null,
        duration: 1500,
      },
    },
    modal4: {
      SETTINGS: {
        target: null,
        route: null,
        action: 'toggle',
        container: 'body',
      },
      ATTRS: {
        id: 'data-modal-id',
        close: 'data-modal-close',
        destroy: 'data-modal-destroy',
      },
      EVENTS: {
        init: null,
        success: null,
        error: null,
        complete: null,
        open: null,
        close: null,
        destroy: null,
        update: null,
      },
      TEMPLATE(type) {
        return `
        <div class="modal-scroller">
          <div class="modal-wrapper" ${type === 'destroy' ? 'data-modal-destroy' : 'data-modal-close'}>
            <div class="modal-content" stop-propagation>
          </div>
        </div>
        `;
      },
    },
    marquee4: {
      SETTINGS: {
        direction: 'left', // 方向 - top || right || bottom || left
        behavior: 'normal', // 模式 - normal || alternate || endStop
        duration: 50000, // 持續時間(毫秒) - Number
        durationBreakpoints: {}, // 持續時間斷點 - Object
        autoplay: 300, // 自動撥放延遲時間(毫秒) - Number || false
        pauseOnMouseenter: false, // 滑鼠移入暫停 - Boolean
        continual: true, // 連續不間斷 - Boolean
        gap: 30, // 跑馬燈內容之間的距離 - Number
      },
    },
    dropdown4: {
      SETTINGS: {
        scrollbar: {
          'scrollbar-track-color': 'transparent',
          'scrollbar-thumb-color': 'rgba(0,0,0,.4)',
          'scrollbar-thumb-hover-color': 'rgba(0,0,0,.55)',
          'scrollbar-radius': '10px',
        },
      },
      TEMPLATE(filter, filterPlaceholder) {
        return `
          <div class="select-wrapper">
            <div class="select-display"></div>
            <i class="dropdown-icon"></i>
            <div class="dropdown">
              ${filter
            ? `<div class="filter-bar">
                  <input class="filter-input" type="text" placeholder="${filterPlaceholder}" disabled>
                </div>`
            : ''
          }
              <div class="dropdown-scroller">
                <ul class="dropdown-list"></ul>
              </div>
            </div>
          </div>
        `;
      },
    },
    article4: {
      SETTINGS: {
        basic_rwd: 900,
      },
    },
    aost4: {
      SETTINGS: {
        scroller: window,
        class: 'aost-show',
        delay: 0,
        start: 80,
        end: 0,
        mirror: false,
        repeat: false, // false | true | 'up' | 'down'
        direction: 'vertical', // 'vertical' | 'horizontal'
        breakpoints: {
          1160: 'data-aost-offset-1160',
          1024: 'data-aost-offset-1024',
          600: 'data-aost-offset-600',
        },
      },
      EVENTS: {
        init: null,
        enter: null,
        leave: null,
      },
    },
    anchor4: {
      SETTINGS: {
        target: null,
        container: null,
        spacer: null,
        gap: 0,
        speed: 800,
        delay: 0,
        direction: 'vertical',
        easing: 'easeInOutCirc',
      },
      EVENTS: {
        afterInit: null,
        beforeDestroy: null,
        afterUpdate: null,
        beforeScroll: null,
        afterScroll: null,
      },
    },
    multipurpose4: {
      SETTINGS: {
        type: 'drag',
        drag: {
          center: true,
          draggable: true,
          navigation: true,
          selected: false,
        },
        collapse: {
          selected: false,
          placeholder: '類別',
        },
        dropdown: {
          target: 'dropdown-el',
          placeholder: '',
          value: '',
        },
        breakpoint: {},
      },
      TEMPLATE: {
        drag() {
          return `<div class="drag-container">
            <ul class="wrapper">
            </ul>
          </div>`;
        },
        collapse(collapse) {
          return `<div class="drag-container">
            <ul class="wrapper">
            </ul>
            <div class="collapse-placeholder">${collapse.placeholder}</div>
          </div>
          <div class="collapse-container">
            <ul class="wrapper">
            </ul>
          </div>`;
        },
        dropdown(dropdown) {
          return `<dropdown-el${dropdown.placeholder ? ` d4-placeholder="${dropdown.placeholder}"` : ''}${dropdown.value ? ` d4-value="${dropdown.value}"` : ''}></dropdown-el>`;
        },
      },
    },
    tab4: {
      SETTINGS: {
        // 樣式 normal / process
        type: 'normal',
        // 進場方式 fade / slide
        display: 'fade',
        // 預設頁面
        defaultPage: '',
        // 滑動扣除高度
        anchorGap: 0,
        tabGroup: 'true',
        // 動畫設定
        transition: {
          duration: 500,
          timing: 'ease',
          delay: 0,
        },
        // 步驟狀態綁定
        stepOutput: '.step-show',
      },
    },
    collapse4: {
      SETTINGS: {
        collapseClass: 'collapse',
        block: '[collapse-block]',
        target: '[collapse-target]',
        single: true,
        defaultOpen: false,
        targetStopPropagation: false,
        defaultActiveMark: 'collapse-active',
        transition: {
          property: 'height',
          duration: '400ms',
          function: 'ease',
          delay: '0s',
        },
        // expand
        breakpoints: {
          320: {},
        },
      },
      // ATTRS: {
      //   wrapper: 'collapse-wrapper',
      //   block: 'collapse-block',
      //   target: 'collapse-target',
      // },
      EVENTS: {
        init: null,
        afterInit: null,
        destroy: null,
        beforeDestroy: null,
        update: null,
        afterUpdate: null,
      },
    },
  };
  window.fesdDB = fesdDB;
})();
